@import './assets/styles/constants.scss';

.app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  color: $primary-color;
  font-family: 'Courier New', Courier, monospace;
  background: $bg-color-primary;
}

.app-content {
  display: flex;
  flex: 1;
  padding-top: 50px;
}
