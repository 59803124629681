@import '../../assets/styles/constants.scss';

.iconWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba($bg-color-secondary-dark, 0.5);
  max-width: 120px;
}

.iconImg {
  height: 100px;
  padding: 10px;
}

.iconLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 10px;
  background-color: $bg-color-primary-light;
  font-weight: 600;
  text-align: center;
}
