//********************* COLORS *********************

// Background
$bg-color-primary: #1a237e;
$bg-color-primary-dark: #000051;
$bg-color-primary-light: #534bae;
$bg-color-secondary: #3e2723;
$bg-color-secondary-dark: #1b0000;
$bg-color-secondary-light: #6a4f4b;

// Text
$primary-color: #ffffff;

// Miscellaneous
$highlight-color: #ffff72;
$white: #fafafa;
$blue-grey-light: #b0bec5;
$blue-grey: #455a64;
$yellow-light: #fff59d;
$purple: #6a1b9a;
$cyan-dark: #00363a;
$light-blue: #58a5f0;
$blue: #1565c0;
$blue-dark: #003c8f;
