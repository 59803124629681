@import '../../assets/styles/constants.scss';

.content {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  // Background animation
  background: linear-gradient(-45deg, $blue-grey, $purple, $blue, $cyan-dark);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.companies {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .cardWrapper {
    box-shadow: 0 5px 10px 0 rgba($bg-color-secondary-dark, 0.5);

    &:not(:first-child) {
      margin-top: 40px;
    }
  }
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
