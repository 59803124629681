@import '../../assets/styles/constants.scss';

@mixin card-title {
  padding: 0 15px;
  text-decoration: none;
  color: $primary-color;
}

.cardContainer {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid $bg-color-secondary-light;
}

.cardHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: $bg-color-secondary;

  .link {
    @include card-title();
    display: flex;
    align-items: center;
    
    &::after {
      content: "\260D";
      padding-left: 8px;
    }
  }

  .name {
    @include card-title();
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .logo {
    height: 50px;
  }
}

.cardContent {
  background-color: $bg-color-primary-light;
}
