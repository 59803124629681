@import '../../assets/styles/constants.scss';

// VARIABLES
$navbar-height: 50px;
$navbar-bg-color: $bg-color-primary-dark;
$navbar-bg-color-light: $bg-color-primary-light;
$small-screen: 400px;

// STYLES
.navbar {
  position: absolute;
  background-color: $navbar-bg-color;
  display: flex;
  height: $navbar-height;
  align-items: center;
  z-index: 1000;
}

.links {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: $small-screen) {
    display: none;

    &.open {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      position: absolute;
      left: 0;
      top: $navbar-height;
      width: 100%;
      background-color: $navbar-bg-color;
      box-shadow: 0 20px 20px rgba(0, 0, 0, 0.8);
      font-size: 20px;

      a {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
  
  a {
    color: $primary-color;
    text-decoration: none;
    padding: 10px;
    
    &:not(:first-child) {
      padding-left: 15px;
    }

    &.active {
      border-bottom: 2px solid $highlight-color;
    }
  }
}

.menuOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

.toggleBtn {
  display: none;
  margin-left: 15px;
  font-weight: 700;
  background-color: transparent;
  border: 2px solid $navbar-bg-color;
  border-radius: 4px;
  color: $primary-color;
  font-size: 30px;

  &:hover {
    border-color: $navbar-bg-color-light;
    cursor: pointer;
  }

  @media screen and (max-width: $small-screen) {
    display: flex;
  }

  &.activeBtn {
    background-color: $navbar-bg-color-light;
  }
}
