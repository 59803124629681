@import '../../assets/styles/constants.scss';

.content {
  padding: 0 20px;
}

.row {
  width: 100%;
}

.link {
  color: $primary-color;
  font-weight: 600;
}
