@import '../../assets/styles/constants.scss';

.content {
  display: flex;
  flex-flow: row wrap;

  & > :not(.bg) {
    z-index: 10;
  } 
}

.headline {
  display: inline-table;
  margin-top: 35vh;
  padding: 20px 40px;
  font-size: 20px;
  text-align: center;

  @media screen and (min-width: 640px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 50px;
  }
}

.summary {
  align-self: flex-end;
  font-size: 15px;

  @media screen and (min-width: 640px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 30px;
  }

  ul {
    margin: 0 0 50px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  li {
    flex: 1;
    padding: 0 5px;
    text-align: center;

    &.divider {
      flex: 0;
      padding: 0 1em;
    }

    @media screen and (min-width:480px) {
      flex: none;
      padding: 0;
    }
  }
}

// Background animation
.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-75deg, $cyan-dark 50%, $bg-color-primary-light 50%);
  bottom: 0;
  left: -50%;
  opacity: .5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: 0;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}
